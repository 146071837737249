const credits = [
  {
    department: "Music",
    intro: [
      "All Music by Plaid, courtesy of Warp Records",
      "Except 'Ghostbusters theme' by Elmer Bernstein & The Hollywood Studio Symphony & 'Meeting 2' by Elmer Bernstein"
    ],
  },
  {
    department: "Cast",
    people: [
      {
        name: "Jordan Bernarde",
        role: "Dr. Peter Venkman",
      },
      {
        name: "Alasdair Buchan",
        role: "Dr. Raymond Stantz",
      },
      {
        name: "Darren Hart",
        role: "Dr. Winston Zeddemore",
      },
      {
        name: "Aurelia Gage",
        role: "Swing",
      },
      {
        name: "Saskia Marguerite",
        role: "Janine Melnitz / Mary O’Connor",
      },
      {
        name: "Frances Knox",
        role: "Janine Melnitz / Dr. Frankie Sherman",
      },
      {
        name: "Odette Galbally",
        role: "Janine Melnitz / Madam Alexandra",
      },
      {
        name: "Aidan Morris",
        role: "Janine Melnitz / Aimée DuBois",
      },
      {
        name: "Holli Dillon",
        role: "Janine Melnitz / Mary O’Connor",
      },
      {
        name: "Lizzie Goodridge",
        role: "Janine Melnitz / Rose Hoffman",
      },
      {
        name: "Manreeve Dhothar",
        role: "Bailiff",
      },
      {
        name: "Charlie MacGechan",
        role: "Billy Fisher (Camera Operator)",
      },
      {
        name: "Ivy Corbin",
        role: "Janine Melnitz",
      },
      {
        name: "Georgina Patrick",
        role: "Dana Barrett",
      },
      {
        name: "Zoë Watson",
        role: "Rose Hoffman / Madam Alexandra",
      },
      {
        name: "Jack Tivey",
        role: "Jim the Maintenance Guy / Dr. Blair Wyndham Jnr",
      },
      {
        name: "Savvy Clement",
        role: "Aimée DuBois / Dr. Frankie Sherman",
      },
      {
        name: "Alex Bird",
        role: "Eugene Gunther / Cal Volthardt / Dr. Blair Wyndham Jnr",
      },
      {
        name: "Calum Gulvin",
        role: "Eugene Gunther / Cal Volthardt / Professor Kit Palladino",
      },
      {
        name: "Joel Samuels",
        role: "Dyb Devlin / Jim the Maintenance Guy",
      },
      {
        name: "Will Taylor",
        role: "Beau Savage / Professor Kit Palladino",
      },
      {
        name: "Sylvester McCoy",
        role: "Dr Rudolph Whitehead",
      },
      {
        name: "Giles Cooper",
        role: "Louis Tully",
      },
      {
        name: "Becky Bassett",
        role: "Party Guest Extra 1",
      },
      {
        name: "Norman Bowman",
        role: "Party Guest Extra 2",
      },
      {
        name: "Stephen Hoo",
        role: "Party Guest Extra 3",
      },
      {
        name: "David Lenik",
        role: "Party Guest Extra 4",
      },
      {
        name: "MJ Lee",
        role: "Party Guest Extra 5",
      },
      {
        name: "Charity Bedu-Addo",
        role: "Party Guest Extra 6",
      },
      {
        name: "Ashraf Ejjbair",
        role: "Party Guest Extra 7",
      },
      {
        name: "Francesca Gilbert",
        role: "Party Guest Extra 8",
      },
      {
        name: "Sachin Sharma",
        role: "Party Guest Extra 9",
      },
      {
        name: "Alex Kais",
        role: "Party Guest Extra 10",
      },
      {
        name: "Dan Smith",
        role: "Party Guest Extra 11",
      },
      {
        name: "James Bryant",
        role: "Party Guest Extra 12",
      },
      {
        name: "Evan Blanque",
        role: "Party Guest Extra 13",
      },
      {
        name: "Lily Leighton",
        role: "Party Guest Extra 14",
      },
      {
        name: "Malikah Mcherrin - Cobb",
        role: "Party Guest Extra 15",
      },
      {
        name: "Cassidy Janson",
        role: "Marina",
      },
      {
        name: "Sachin Sharma",
        role: "Gozerian Cult Member 1",
      },
      {
        name: "Jerome Blake",
        role: "Gozerian Cult Member 2",
      },
      {
        name: "Coral Bevan",
        role: "Gozerian Cult Member 3",
      },
      {
        name: "Norman Bowman",
        role: "Gozerian Cult Member 4",
      },
      {
        name: "Joe Leather",
        role: "Dyb Devlin / Beau Savage",
      },
      {
        name: "Georgia Redgrave",
        role: "Gozer",
      },
    ],
  },
  {
    department: "Creative",
    people: [
      {
        name: "Matthew Bennett",
        role: "Creative Director - Production",
      },
      {
        name: "Lucy Ridley",
        role: "Creative Director - Development",
      },
      {
        name: "Hermione Benest",
        role: "Creative Producer",
      },
      {
        name: "Andrew Keates",
        role: "Performance Director",
      },
      {
        name: "Ian Galloway",
        role: "Video & Digital Director",
      },
      {
        name: "Ella Prendergast",
        role: "Script Writer",
      },
      {
        name: "Orlando Seale",
        role: "Story Writer",
      },
      {
        name: "Sabrina Goreeba",
        role: "Director of Content",
      },
      {
        name: "Fraser Gillespie",
        role: "Head of Graphics",
      },
      {
        name: "Natasha Thomas",
        role: "Graphic Designer",
      },
      {
        name: "James Land",
        role: "Graphic Designer",
      },
      {
        name: "Lassamie Prasimay",
        role: "Creative Assistant",
      },
    ],
  },
  {
    department: "Production",
    people: [
      {
        name: "Brittany Woodhams",
        role: "Producer",
      },
      {
        name: "Susan Arndt",
        role: "Platform Director",
      },
      {
        name: "Carly Morrell",
        role: "Production and Locations Director",
      },
      {
        name: "Anna Parcerisas",
        role: "Production Coordinator",
      },
      {
        name: "Manreeve Dhothar",
        role: "Production Assistant",
      },
      {
        name: "Phil Wilding",
        role: "Production Manager",
      },
      {
        name: "Luke Mills",
        role: "Production Manager - Infrastructure",
      },
      {
        name: "Andrea Moccia",
        role: "Producer - Development",
      },
      {
        name: "Amy O'Brien",
        role: "Associate Producer - Development",
      },
      {
        name: "Ramona Lerche",
        role: "Operations Manager",
      },
      {
        name: "Jon Mattey",
        role: "Health & Safety Advisor",
      },
      {
        name: "Joe Carter",
        role: "Health & Safety Advisor",
      },
    ],
  },
  {
    department: "Digital",
    people: [
      {
        name: "Johnny Walker",
        role: "Director of Digital Technology",
      },
      {
        name: "Tricia Cuninghame",
        role: "Senior Web Developer",
      },
    ],
  },
  {
    department: "Secret Group",
    people: [
      {
        name: "Max Alexander",
        role: "Chief Executive Officer",
      },
      {
        name: "Nicola Blackford",
        role: "Commercial Director",
      },
      {
        name: "Katie Davidson",
        role: "Director of Slate & Business Affairs",
      },
      {
        name: "Damian Macaulay",
        role: "Chief Finance Officer / Chief Operational Officer",
      },
      {
        name: "Jenny Glanville",
        role: "Financial Controller",
      },
      {
        name: "Charlene Pestana",
        role: "Production Accountant",
      },
      {
        name: "Thomas Allott",
        role: "Head of Business Development",
      },
      {
        name: "Talisa Adcock",
        role: "Operations Manager",
      },
      {
        name: "Zane Rambaran",
        role: "Ticketing Manager",
      },
      {
        name: "Becky Bevan",
        role: "Head of Creative Apparel and Merchandise",
      },
      {
        name: "Alex Ward",
        role: "Director of Partnerships",
      },
      {
        name: "Phoebe Owen",
        role: "Partnerships Account Manager",
      },
    ],
  },
  {
    department: "Marketing",
    people: [
      {
        name: "Ayomi Rupasinghe",
        role: "UK Marketing Director",
      },
      {
        name: "Freddie O'Shea",
        role: "Communications and Insights Manager",
      },
      {
        name: "Linn Wiberg",
        role: "Digital Marketing Manager",
      },
      {
        name: "James Anderson",
        role: "Head of CRM",
      },
      {
        name: "Jingying Liu",
        role: "Head of Performance Marketing",
      },
      {
        name: "Cristina Alvarado",
        role: "US Customer Service Exec",
      },
    ],
  },
  {
    department: "Promotion",
    people: [
      {
        name: "Tim Potter",
        role: "Managing Director - Hunt & Gather",
      },
      {
        name: "Katie FitzPatrick",
        role: "Associate Director - Hunt & Gather",
      },
      {
        name: "Naomi Walsh",
        role: "Senior Account Manager - Hunt & Gather",
      },
      {
        name: "Natalia Katsionis",
        role: "Account Manager - Hunt & Gather",
      },
    ],
  },
  {
    department: "The Acceleration Agency",
    people: [
      {
        name: "Starr Long",
        role: "Executive Producer",
      },
      {
        name: "Rodney White",
        role: "Chief Technology Officer",
      },
      {
        name: "Paul Kane",
        role: "Lead Engineer",
      },
      {
        name: "Darren Wong",
        role: "Creative Director",
      },
      {
        name: "Richard Primeaux",
        role: "Senior Producer",
      },
      {
        name: "Mindy Wong",
        role: "Creative Producer",
      },
      {
        name: "Daniel Bourdage",
        role: "Senior Producer",
      },
      {
        name: "Matt Gilliatt",
        role: "Web Designer",
      },
      {
        name: "David Land",
        role: "Senior Engineer",
      },
      {
        name: "Michael Schipper",
        role: "Senior Engineer",
      },
      {
        name: "Doug Warner",
        role: "Web Developer",
      },
      {
        name: "Kathy Tieu",
        role: "Designer",
      },
      {
        name: "Cartrell Hampton",
        role: "Game Engineer",
      },
      {
        name: "Bohdan Skarzhevskyi",
        role: "Engineer",
      },
      {
        name: "Igor Zhuk",
        role: "Engineer",
      },
      {
        name: "Ihor Dytskyi",
        role: "Engineer",
      },
    ],
  },
  {
    department: "Dusty Cupboard Company",
    people: [
      {
        name: "Kyle Prestenback",
        role: "Experience Platform Consultant",
      },
    ],
  },
  {
    department: "Video",
    intro: ["Video design, systems, infrastructure and integration by Mesmer"],
    people: [
      {
        name: "Ian William Galloway",
        role: "Video & Digital Director",
      },
      {
        name: "Salvador Bettencourt Ávila",
        role: "Head of Video / System Designer & Programmer",
      },
      {
        name: "Caitlyn Russell",
        role: "Video Operator",
      },
      {
        name: "Ben Walden",
        role: "Video Operator",
      },
      {
        name: "Nicholas Ward",
        role: "Video Technician",
      },
      {
        name: "John O'Connell",
        role: "Technical Manager",
      },
      {
        name: "Barbora Šenoltová",
        role: "Content Design Associate",
      },
      {
        name: "James Lawson",
        role: "Storyboard Artist",
      },
      {
        name: "Alfie Vaughan",
        role: "VFX Artist",
      },
      {
        name: "Lucy Baker-Swinburn",
        role: "Content Audio Editor",
      },
      {
        name: "Rafael Vartanian",
        role: "3D Animator",
      },
      {
        name: "Dimitris Simou",
        role: "3D Animator",
      },
      {
        name: "Jachym Bouzek",
        role: "Animator",
      },
      {
        name: "Cheng Keng",
        role: "Assistant Animator",
      },
      {
        name: "Rena Iizawa",
        role: "Assistant Animator",
      },
      {
        name: "Gabi Norland",
        role: "Director of Photography",
      },
      {
        name: "Grant Freeman",
        role: "1st Assistant Director",
      },
      {
        name: "Malcolm Cooper",
        role: "Movi Op",
      },
      {
        name: "Jon Mitchell",
        role: "Focus Puller",
      },
      {
        name: "Irene Sinou",
        role: "Clapper Loader",
      },
      {
        name: "Wolf Wasserman",
        role: "Grip",
      },
      {
        name: "Nat Valente",
        role: "Playback",
      },
      {
        name: "Jack Freeman Bell",
        role: "Runner",
      },
      {
        name: "Rob Shears",
        role: "Gaffer",
      },
      {
        name: "Craig Gambell",
        role: "Gaffer",
      },
      {
        name: "Hayden Jones",
        role: "Spark",
      },
      {
        name: "Haresh Patel",
        role: "Sound Recordist",
      },
      {
        name: "Michael O'Donaghue",
        role: "Assistant Sound Recordist",
      },
    ],
  },
  {
    department: "Sound",
    people: [
      {
        name: "Gareth Fry",
        role: "Sound Designer",
      },
      {
        name: "Luke Swaffield for Autograph",
        role: "Head of Sound",
      },
      {
        name: "Jim Douglas for Autograph",
        role: "Production Sound Engineer",
      },
    ],
  },
  {
    department: "Production Design",
    people: [
      {
        name: "Sean Turner",
        role: "Production Designer",
      },
    ],
  },
  {
    department: "Art",
    people: [
      {
        name: "Mariangela Mazzeo",
        role: "Assistant Production Designer",
      },
      {
        name: "Ryan O'Conner",
        role: "Art Director",
      },
      {
        name: "Phil Wilding",
        role: "Production Manager - Scenic",
      },
      {
        name: "Jenny Grand",
        role: "Art Dept Coordinator",
      },
      {
        name: "Emma Douglas",
        role: "Assistant Art Director",
      },
      {
        name: "Merve Uzuner",
        role: "Set Decorator/ Props Buyer",
      },
      {
        name: "Alice Cox",
        role: "Art Dept Prop Fabricator",
      },
      {
        name: "Jimmy Mann",
        role: "Art Dept Prop Fabricator",
      },
      {
        name: "Edoardo Lelli",
        role: "Art Dept Assistant",
      },
      {
        name: "Bronia Toppley",
        role: "Art Dept Prop Fabricator",
      },
      {
        name: "Chris Jenkins",
        role: "Art Carpenter",
      },
      {
        name: "Monk Stevens",
        role: "Art Carpenter",
      },
      {
        name: "James Woolhead",
        role: "Art Carpenter",
      },
      {
        name: "Conor McMahon",
        role: "Art Carpenter",
      },
      {
        name: "Ben Bailey de Paor",
        role: "Art Carpenter",
      },
      {
        name: "Louise Worrall",
        role: "Scenic Painter",
      },
      {
        name: "Jo Earles",
        role: "Scenic Painter",
      },
      {
        name: "Scarlett McLean",
        role: "Scenic Painter",
      },
      {
        name: "Isobel Nicolson",
        role: "Scenic Painter",
      },
      {
        name: "Gabby Moore",
        role: "Scenic Painter",
      },
    ],
  },
  {
    department: "Costume",
    people: [
      {
        name: "Sean Turner",
        role: "Costume Designer",
      },
      {
        name: "Nicole Bowden",
        role: "Costume Supervisor",
      },
      {
        name: "Frances Morris",
        role: "Costume Breakdown Artist",
      },
      {
        name: "Carolina Wetterstad",
        role: "Costume Breakdown Artist",
      },
      {
        name: "Nicole Bowden",
        role: "Costume Maker",
      },
      {
        name: "Li-Lee Choo",
        role: "Costume Assistant",
      },
      {
        name: "Anna Crilly",
        role: "Costume Assistant",
      },
      {
        name: "Rachel Perry",
        role: "Costume Maker",
      },
      {
        name: "Annoushka Baker",
        role: "Costume Assistant",
      },
      {
        name: "Shoni Wilkinson",
        role: "Costume Maker",
      },
      {
        name: "Katy Adeney",
        role: "Costume Maker",
      },
    ],
  },
  {
    department: "Lighting",
    people: [
      {
        name: "Neill Brinkworth",
        role: "Lighting Designer",
      },
      {
        name: "Pete Bridgeman",
        role: "Programmer",
      },
      {
        name: "Adam Povey",
        role: "Head of Lighting",
      },
      {
        name: "Ollie Partridge",
        role: "Production Electrician",
      },
      {
        name: "Mike Smith",
        role: "Production Electrician",
      },
      {
        name: "Alan Watson",
        role: "Production Electrician",
      },
      {
        name: "James Bentley",
        role: "Production Electrician",
      },
      {
        name: "David Gregory",
        role: "Production Electrician",
      },
      {
        name: "James Huntsman",
        role: "Production Electrician",
      },
      {
        name: "George Thurstan",
        role: "Production Electrician",
      },
      {
        name: "Iris Willems",
        role: "Production Electrician",
      },
    ],
  },
  {
    department: "Hair & Make Up",
    people: [
      {
        name: "Estefania Burgos",
        role: "Hair and Make Up Designer",
      },
      {
        name: "Susana Rodero",
        role: "SFX Hair and Make Up Designer",
      },
      {
        name: "Fiona Tanner",
        role: "Hair and Make Up Assistant",
      },
      {
        name: "Gilly Church",
        role: "Hair & Make Up Supervisor",
      },
    ],
  },
  {
    department: "Stage Management",
    people: [
      {
        name: "Louise Tischler",
        role: "Company Manager",
      },
      {
        name: "Jo Alexander",
        role: "Stage Manager",
      },
      {
        name: "Alix Mayhew",
        role: "Assistant Stage Manager",
      },
      {
        name: "Alex Hobbs",
        role: "Assistant Stage Manager",
      },
    ],
  },
  {
    department: "Merchandise",
    people: [
      {
        name: "Becky Bevan",
        role: "Head of Creative Apparel and Merchandise",
      },
      {
        name: "Alexandra Hart",
        role: "Creative Apparel and Merchandise Buyer",
      },
      {
        name: "Kim Scopes",
        role: "Fulfilment Manager",
      },
      {
        name: "Alex Baker",
        role: "Assistant Fulfilment Manager",
      },
    ],
  },
];

export default credits;
