<template>
  <div class="pre-body pre-body-md">
    <div class="pre-subhead pre-subhead-border centered">
      <div class="pre-divider"></div>
      <div class="pre-subhead--copy text-uppercase">Credits</div>
      <div class="pre-divider"></div>
    </div>

    <div class="pre-pad"></div>

    <div
      class="text-center mb-5"
      v-for="(department, depId) in allCredits"
      :key="depId"
    >
      <div class="pre-subhead text-center mb-5">
        {{ department.department }}
      </div>
      <div v-if="department.intro">
        <p v-for="(para, index) in department.intro" :key="index">{{ para }}</p>
      </div>
      <div
        v-for="person in department.people"
        :key="person"
        class="row my-3 my-md-0"
      >
        <div class="col-12 col-md-6 text-md-right">{{ person.name }}</div>
        <div class="col-12 col-md-6 text-md-left">{{ person.role }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import credits from "../constants/creditsConstants";
export default {
  name: "Credits",
  data() {
    return {
      allCredits: credits,
    };
  },
};
</script>
